import { NgModule } from '@angular/core';
import { UnitConditionPipe } from './unit.condition.pipe';

@NgModule({
  declarations: [
    UnitConditionPipe
  ],
  exports: [
    UnitConditionPipe
  ],
  providers: [
    UnitConditionPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'unitCondition',
      useClass: UnitConditionPipe
    }
  ]
})
export class UnitsModule {}
