import {
  Component,
  Input
} from '@angular/core';

import * as sprite from 'icons/workshop.svg';

import { IconThemeModel } from './typings/icon.theme';

@Component({
  selector   : 'wor-icon',
  styles: [ require('./icon.component.scss') ],
  template: require('./icon.component.html')
})
export class IconComponent {
  public sprite : string = sprite;

  @Input() icon  : string;
  @Input() theme : IconThemeModel = 'workshop';

  isTheme ( theme : IconThemeModel = 'workshop' ) : boolean {
    return theme === this.theme;
  }
}
