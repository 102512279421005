import { CommonModule } from '@angular/common';
import { FormsModule  } from '@angular/forms';
import { NgModule     } from '@angular/core';

import { ApiModule               } from './api/api.module';
import { AzureSsoModule          } from './azure-sso/azure-sso.module';
import { BillerModule            } from './biller/biller.module';
import { ChargifyModule          } from './chargify/chargify.module';
import { CompaniesModule         } from './companies/companies.module'
import { CoreModule              } from './core/core.module';
import { CustomerPaymentsModule  } from './customer-payments/customer-payments.module';
import { CustomersModule         } from './customers/customers.module';
import { EmailVerificationModule } from './email-verification/email-verification.module';
import { GsfModule               } from './gsf/gsf.module';
import { InvoicesModule          } from './invoices/invoices.module';
import { MessagesModule          } from './messages/messages.module';
import { NetoModule              } from './neto/neto.module';
import { PartstechModule         } from './partstech/partstech.module';
import { ProductsModule          } from './products/products.module';
import { RecpatchaModule         } from './recaptcha/recaptcha.module';
import { SessionsModule          } from './sessions/sessions.module';
import { SharedModule            } from './shared/shared.module';
import { StripeModule            } from './stripe/stripe.module';
import { TableModule             } from './table/table.module';
import { TransactionCenterModule } from './transaction-center/transaction-center.module';
import { UnitsModule             } from './units/units.module';
import { VendorsModule           } from './vendors/vendors.module';

@NgModule({
  imports: [

    // Vendor modules.
    CommonModule,
    FormsModule,

    // Feature modules.
    ApiModule,
    AzureSsoModule,
    BillerModule,
    ChargifyModule,
    CompaniesModule,
    CoreModule,
    CustomerPaymentsModule,
    CustomersModule,
    EmailVerificationModule,
    GsfModule,
    InvoicesModule,
    MessagesModule,
    NetoModule,
    PartstechModule,
    ProductsModule,
    RecpatchaModule,
    SessionsModule,
    SharedModule,
    StripeModule,
    TableModule,
    TransactionCenterModule,
    UnitsModule,
    VendorsModule
  ]
})
export class AppLiteModule {}
